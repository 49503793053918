.top {
    padding-left: 10px;
    margin-bottom: 15px;
    .title {
        margin-bottom: 5px;
    }
}



.floating {  
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating {
    from { transform: translate(0,  0px); }
    50%  { transform: translate(0, 10px); }
    to   { transform: translate(0, -0px); }    
}


input[type="file"] {
    display: none;
}

.progress {
    margin-top: 6px;
    position: absolute;

    .circular {
        margin-top: 24px!important;
        position: absolute;
    }
}

.btn {
    margin-top: 15px!important;

    &.delete,&.cancel {
        margin-left: 10px;
    }
}

.photos-container {
    @media screen and (max-width: 960px) {
        justify-content: center;
    }
}