body, html, #root,.app,.app-container {
    height: 100%;
    margin: 0;
    padding: 0;
}

.app {
    div.page {
        padding-top: 79px;
        padding-left: 242px;
        min-height: calc(100% - 79px);
        @media screen and (max-width: 960px) {
            padding-left: 0!important;
            padding-bottom: 55px;
        }

    }
}

a {
    text-decoration: none!important;
    color: rgba(0, 0, 0, 0.55)!important;
}

.relative {
    position: relative;
}

.flex {
    flex: 1;
}
.fab-button {
    position: fixed!important;
    bottom: 15px;
    right: 15px;
}

.hide-desktop {
    @media screen and (min-width: 960px) {
        display: none!important;
    }
}