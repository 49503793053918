.rows-container {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 960px) {
        justify-content: center;
    }
}

.update-buttons {
    padding-left: 10px;
}