.login-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%!important;
    padding: 0!important;

    background-image: url("https://images.pexels.com/photos/807598/pexels-photo-807598.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=1080&w=1920");
    background-size: cover;

    .paper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 35px;
        width: 460px;
        height: 240px;

        form {
            width: 100%;
            
            .login-button {
                margin-top: 20px;
                background-color: #212121;
                &:hover {
                    background-color: #424242;
                }
            }
        }
    }
}