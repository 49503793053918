.page.contact {
    margin: 15px;

    .save-button {
        margin-top: 15px;
    }

    .image-container {
        margin-left: 15px;
    }
}