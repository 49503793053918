.page.about {
    margin: 15px;

    .save-button {
        margin-top: 15px;
    }
    

    .import-button {
        margin-bottom: 15px;
    }
}